import app from "firebase/app";

export const convertDateToFirestoreTimestamp = (date) => {
    return app.firestore.Timestamp.fromDate(date);
};

export const executeSearchQuery = (query) => {
    return query.get().then(function (results) {
        if (!results.empty) {
            return results;
        }
    }).catch(function (error) {
        console.log("Error getting documents:", error);
        return [];
    });
};