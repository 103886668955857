import React, { Component } from "react";
import { compose } from "recompose";
import './Admin.css';
import { withRouter } from "react-router-dom";
import { withAuthorization } from "../../session/index";
import { getUserRole } from "../../static/localStorage";
import * as ROLES from "../../constants/roles";
import { Button } from "reactstrap";
import UsersTable from "../../components/admin/UsersTable/UsersTable";
import LocationsTable from "../../components/admin/LocationsTable/LocationsTable";
import FaultCodesTable from "../../components/admin/FaultCodesTable/FaultCodesTable";
import FaultCodesBasicTable from "../../components/admin/FaultCodesTable/FaultCodesBasicTable";
import { AdminDetailsIcon } from "../../components/elements/Icons/Icons";
import ReportsTable from "../../components/admin/ReportsTable/ReportsTable";
import ReportsBasicTable from "../../components/admin/ReportsTable/ReportsBasicTable";

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeUsers: true,
            activeLocations: false,
            activeFaultCodes: false,
            activeFaultBasicCodes: false,
            activeReports: false,
            activeReportsBasic: false
        }
    }

    setActiveTab = ({ activeUsers, activeLocations, activeFaultCodes, activeFaultBasicCodes, activeReports, activeReportsBasic }) => {
        this.setState({
            activeUsers: activeUsers || false,
            activeLocations: activeLocations || false,
            activeFaultCodes: activeFaultCodes || false,
            activeFaultBasicCodes: activeFaultBasicCodes || false,
            activeReports: activeReports || false,
            activeReportsBasic: activeReportsBasic || false,
        })
    };

    render() {
        const { activeUsers, activeLocations, activeFaultCodes, activeFaultBasicCodes, activeReports, activeReportsBasic } = this.state;
        return (
            <div id={'admin'}>
                <div className={'admin-header'}>
                    <AdminDetailsIcon />
                    <div>
                        <h3>Admin Settings</h3>
                    </div>
                </div>
                <div className='admin-nav'>
                    <Button outline={!activeUsers} color="danger" onClick={() => this.setActiveTab({ activeUsers: true })}>Users</Button>
                    <Button outline={!activeLocations} color="danger" onClick={() => this.setActiveTab({ activeLocations: true })}>Locations</Button>
                    <Button outline={!activeReports} color="danger" onClick={() => this.setActiveTab({ activeReports: true })}>Advanced Category</Button>
                    {/* <Button outline={!activeFaultCodes} color="danger" onClick={() => this.setActiveTab({activeFaultCodes:true})}>Advanced Fault codes</Button>                     */}
                    <Button outline={!activeReportsBasic} color="danger" onClick={() => this.setActiveTab({ activeReportsBasic: true })}>Standard Category</Button>
                    {/* <Button outline={!activeFaultBasicCodes} color="danger" onClick={() => this.setActiveTab({ activeFaultBasicCodes: true })}>Basic Fault codes</Button> */}
                </div>
                <div className='admin-body'>
                    {activeUsers && <UsersTable />}
                    {activeLocations && <LocationsTable />}
                    {activeReports && <ReportsTable />}
                    {/* {activeFaultCodes && <FaultCodesTable/>} */}
                    {activeReportsBasic && <ReportsBasicTable />}
                    {/* {activeFaultBasicCodes && <FaultCodesBasicTable />} */}
                </div>
            </div>
        );
    }
}
const userRole = getUserRole();
const condition = authUser => {
    const userRole = getUserRole();
    return userRole && userRole !== null && userRole.toUpperCase() === ROLES.ADMIN.toUpperCase() ? !!authUser : !authUser;

} 
export default compose(
    withAuthorization(condition), withRouter
)(Admin);
