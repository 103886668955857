import React, {Component} from 'react';
import './UsersTable.css';
import {Table, Input, Button} from "reactstrap";
import MaterialIcon from "material-icons-react";
import UserRow from "./UserRow";
import {getAllUser, updateUser} from "../../../firebase/api/users";
import Loader from "../../elements/Loader/Loader";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import {getAllLocation} from "../../../firebase/api/locations";
import AddUserForm from "../AddUserForm/AddUserForm";

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addUser: false,
            showLoader: false,
            showSuccessModal: false,
            showAddUser: false,
            deletedUser: '',
            dto: {
                name: '',
                location: '',
                role: '',
            },
            users: [],
            locations: [],
        }
    }

    componentDidMount() {
        const {users, locations} = this.state;

        this.setState({showLoader: true});
        getAllUser().then(results => {
            if (results) {

                results.forEach(function (doc) {
                    const data = doc.data();
                    data.uid = doc.ref.id;
                    users.push(data);
                });

                getAllLocation().then(results => {
                    if (results) {
                        results.forEach(function (doc) {
                            const data = doc.data();
                            data.uid = doc.ref.id;
                            locations.push(data);
                        });
                    }
                });

                this.setState({users, locations, showLoader: false});
            }
        })
    }

    onInputChange = event => {
        const {dto} = this.state;
        dto[event.target.name] = event.target.value;
        this.setState({dto});
    };

    editUser = (dto, uid, index) => {
        const {users} = this.state;
        updateUser(dto, uid).then(result => {
            if(result){
                users[index].name = dto.name;
                users[index].location = dto.location;
                users[index].role = dto.role;
                this.setState({users});
            }
        })
    };

    addNewUserRow = (user) => {
        const {users} = this.state;
        console.log(users);
        users.push(user);
        this.setState({users});
    };

    deleteUser = (user) => {
        const {users} = this.state;
        console.log(users);
        const filterUser = users.filter(tmpUser => tmpUser.uid !== user.uid)
        this.setState({users: filterUser, deletedUser: user});
        this.handleSuccessModal();
    };

    handleSuccessModal = () => {
        this.setState({ showSuccessModal: true})
    }

    successModalToggle = () => {
        this.setState(prevState => ({
            showSuccessModal: !prevState.showSuccessModal,
            deletedUser: ''
        }));
    }

    render() {
        const {addUser, dto, users, showLoader, locations, showSuccessModal, deletedUser} = this.state;
        const isButtonDisabled = dto.name && dto.location && dto.role;
        return (
            <div className='users-table'>
                <AddUserForm addNewUserRow={this.addNewUserRow} locations={locations}/>
                {showLoader
                    ? <Loader/>
                    : <Table bordered>
                        <thead>
                        <tr>
                            <th style={{width:"25%"}}>Name</th>
                            <th style={{width:"30%"}}>Location</th>
                            <th style={{width:"30%"}}>Role</th>
                            <th style={{width:"15%"}}>Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users && users.map((user, index) => (
                            <UserRow
                                key={index}
                                index={index}
                                user={user}
                                locations={locations}
                                deleteUser={this.deleteUser}
                                editUser={this.editUser}
                            />
                        ))}
                        {/*<tr>*/}
                        {/*    <td>{addUser && <Input name={'name'} placeholder={'Name'} value={dto.name}*/}
                        {/*                           onChange={event => this.onInputChange(event)}/>}</td>*/}
                        {/*    <td>{addUser && <Input name={'location'} placeholder={'Location'} value={dto.location}*/}
                        {/*                           onChange={event => this.onInputChange(event)}/>}</td>*/}
                        {/*    <td>{addUser && <Input name={'role'} value={dto.role} placeholder={'Role'}*/}
                        {/*                           onChange={event => this.onInputChange(event)}/>}</td>*/}
                        {/*    <td>*/}
                        {/*        {addUser*/}
                        {/*            ? <div className='d-flex'>*/}
                        {/*                <button className='option-button'*/}
                        {/*                        onClick={() => this.addNewUser()} disabled={!isButtonDisabled}>*/}
                        {/*                    <MaterialIcon icon="how_to_reg"/>*/}
                        {/*                </button>*/}
                        {/*                <button className='option-button'*/}
                        {/*                        onClick={() => {*/}
                        {/*                            const {dto} = this.state;*/}
                        {/*                            dto.name = "";*/}
                        {/*                            dto.location = "";*/}
                        {/*                            dto.role = "";*/}
                        {/*                            this.setState({addUser: !addUser, dto});*/}
                        {/*                        }}>*/}
                        {/*                    <MaterialIcon icon="reply"/>*/}
                        {/*                </button>*/}

                        {/*            </div>*/}
                        {/*            : <button className='option-button'*/}
                        {/*                      onClick={() => this.setState({addUser: !addUser})}*/}
                        {/*            >*/}
                        {/*                <MaterialIcon icon="person_add"/>*/}
                        {/*            </button>}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        </tbody>
                    </Table>}
                            
                    <ConfirmationModal toggle={this.successModalToggle}
                                               modal={showSuccessModal}
                                               header={'Success'}
                                               body={`${deletedUser.name} Deleted Successfully`}
                                               showButton={false}
                            />
            </div>
        )
    }
}

export default UsersTable