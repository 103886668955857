import app from "firebase/app";
import {executeSearchQuery} from "./firestoreUtils";


const getLocationCollection = () => app.firestore().collection("locations");

export const getAllLocation = () => {
    const query = buildSearchQuery();
    return executeSearchQuery(query);
};

export const createLocation = (dto) => {
    return new Promise(function (resolve, reject) {
        const docRef = getLocationCollection().doc();
        // dto.uid = docRef.id;
        docRef.set({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

export const updateLocation = (dto, uid) => {
    return new Promise(function (resolve, reject) {
        const docRef = getLocationCollection().doc(uid);
        docRef.update({...dto});
        if (docRef && docRef.id) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};

export const deleteLocation = (uid) => {
    return getLocationCollection().doc(uid).delete()
};


const buildSearchQuery = () => {
    return getLocationCollection();
};

