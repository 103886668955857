import React from 'react';
import './NavBar.css'
import {Button} from 'reactstrap';
import {withAuthorization} from "../../../session/index";
import {getUserRole, getUserUid} from "../../../static/localStorage";
import {getUserByUid} from "../../../firebase/api/users";
import UserIcon from "../../elements/UserIcon/UserIcon";
import Loader from "../../elements/Loader/Loader";
import * as ROUTES from "../../../constants/routes";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import * as ROLES from "../../../constants/roles";
import {
    AdvancedIcon,
    AllIcon,
    BasicIcon,
    BookInspectionIcon,
    ContactUsIcon,
    FaqIcon,
    SettingsIcon
} from "../../elements/Icons/Icons";

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userRole: getUserRole(),
            user: null
        };
    }

    componentDidMount() {
        const uId = getUserUid();
        getUserByUid(uId).then(result => {
            const user = {
                name: result ? result.name : 'Admin Admin',
                location: result ? result.location : 'unknown',
                position: result ? result.role :''
            };
            this.setState({user})
        });
    }

    render() {
        const {activeAllBooks, activeBasicBooks, activeAdvancedBooks, signOut, setActiveTab, refreshGrid, dateRange} = this.props;
        const {user, userRole} = this.state;
        const {pathname} = this.props.location;
        return (
            <div className={'navBar'}>
                {user ? <div className={'userWrapper'}>
                    <UserIcon userName={user.name}/>
                    <p>{user.name}</p>
                    <p>{user.position}</p>
                </div> : <div className={'userWrapper'}><Loader/></div>
                }
                <Button color="danger"
                        className={'bookInspectionButton'}
                        onClick={() => this.props.history.push(ROUTES.CREATE)}><BookInspectionIcon/> Book Inspection</Button>
                <nav className={'inspections'}>
                    <h6 className="inspections title">Inspections</h6>
                    <div
                        className={`filter ${activeAllBooks ? 'active' : 'disabled'}`}
                        onClick={() => setActiveTab({activeAllBooks: true}, () => refreshGrid(true, null, dateRange))}
                    ><AllIcon/> All
                    </div>
                    <div
                        className={`filter ${activeBasicBooks ? 'active' : 'disabled'}`}
                        onClick={() => setActiveTab({activeBasicBooks: true}, () => refreshGrid(true, null, dateRange))}
                    ><BasicIcon/> Standard
                    </div>
                    <div
                        className={`filter ${activeAdvancedBooks ? 'active' : 'disabled'}`}
                        onClick={() => setActiveTab({activeAdvancedBooks: true}, () => refreshGrid(true, null, dateRange))}
                    ><AdvancedIcon/> Advanced
                    </div>
                </nav>
                {userRole && userRole !== null  && userRole.toUpperCase() === ROLES.ADMIN.toUpperCase() && <div className={`settingsButton ${this.props.location.pathname === ROUTES.ADMIN && 'active'}`} onClick={()=> {this.props.history.push(ROUTES.ADMIN)} }><SettingsIcon/> Admin Settings</div>}
                {/*<nav className={'support'}>*/}
                {/*    <h6>SUPPORT</h6>*/}
                {/*    <a href={'#'}><ContactUsIcon/> Contact us</a>*/}
                {/*    <a href={'#'}><FaqIcon/> FAQ</a>*/}
                {/*</nav>*/}
                <Button onClick={signOut}>Logout</Button>
            </div>
        );
    }
}


const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition), withRouter
)(NavBar);