import { api} from './api';
import {ALGO_DRIVEN_SERVER_URL} from "../configs";

export const getVehicleByVin = (body) => (
    api.post({
        path: `${ALGO_DRIVEN_SERVER_URL}/v1/gccfactoryvinext`,
        body: body,
        isFormData: true
    })
);

// Get Vehicle image by VIN
/*
export const getVehicleImageByVin = (body) => (
    api.post({
        path: `${ALGO_DRIVEN_SERVER_URL}/v1/getstockimg`,
        body: body,
        isFormData: true
    })
);
*/

// Get Car logo brand by VIN
export const getVehicleImageByVin = (body) => (
    api.post({
        path: `${ALGO_DRIVEN_SERVER_URL}/v1/getbrandlogo`,
        body: body,
        isFormData: true
    })
);

export const getVehicleByMulkiya = (body) => (
    api.post({
        path: `${ALGO_DRIVEN_SERVER_URL}/v1/sendMulkiya`,
        body: body,
        isFormData: true
    })
);

export const sendEmail = (body) => (
    api.post({
        path: `https://us-central1-d-inpsect.cloudfunctions.net/resendReport`,
        body: body,
    })
);

export const getPdfUrl = (body) => {
    console.log("bodybody",body)
    return(
    api.post({
        path: `https://us-central1-d-inpsect.cloudfunctions.net/getInspectionReport?inspectionId=${body.inspectionId}&noEmail=1`,
        // body: body,
    })
)};

export const getCarLogo = (body) => (
    api.post({
        path: "https://evalexpert.io/v1/getbrandlogo",
        body: body,
    })
)

export const redoInspection = (inspectionId) => {
    return(
      api.post({
        path: `https://us-central1-d-inpsect.cloudfunctions.net/redoInspection?inspectionId=` + inspectionId,
        inspectionId: inspectionId,
    })
)};