import React, {Component} from "react";
import {compose} from "recompose";
import './Booking.css';
import {withRouter} from "react-router-dom";
import {withAuthorization} from "../../session/index";
import Grid from "../../components/booking/Grid/Grid"
import {SearchIcon} from "../../components/elements/Icons/Icons";
import {Button, FormGroup, Input} from 'reactstrap';
import DatePicker from "react-datepicker/es";
import IntlTelInput from "react-intl-tel-input";
import DateSelector from "../../components/booking/DateSelector/DateSelector";
import Inspection from "../../components/booking/Inspection/Inspection";
import Slider from '@material-ui/core/Slider';
import MaterialIcon from "material-icons-react";
import Typography from '@material-ui/core/Typography';

class Booking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchBar: false,
            startDate: null,
            filteredHits: [],
            showFilter: false
        }
    }

    valuetext = (rangeSliderValues) => {
        return `${rangeSliderValues}°C`;
    }

    showSearchBarHandler = () => {
        console.log('showSearchBarHandler');
        const {updateGrid} = this.props;
        this.setState(prevState => ({
            showSearchBar: !prevState.showSearchBar
        }), () => {
            // this.props.getAllInspections()
            // const {showSearchBar} = this.state;
            // if (!showSearchBar) {
                updateGrid(() => {});
            // }
        });

        const inputKey = Object.keys(this.props.search);
        inputKey.forEach(key => {
            this.props.onInputChange({
                target: {
                    name: key,
                    value: ''
                }
            });
        });
        // this.props.searchInspection();
    };


    removeDuplicates = (myArr) => {
        console.log('Array.from(new Set(myArr.map(a => a.inspectionRef)))', Array.from(new Set(myArr.map(a => a.inspectionRef))))
       return Array.from(new Set(myArr.map(a => a.inspectionRef)))
       .map(inspectionRef => {
         return myArr.find(a => a.inspectionRef === inspectionRef)
       })
    }

    
    render() {
        const {inspections, activeBasicBooks, activeAdvancedBooks, search, handleChange, onInputChange, searchInspection, noSearchResults, setDateRange, dateRange, handleChangeSlider, rangeSliderValues} = this.props;
        const {showSearchBar, showFilter} = this.state;
        return (
            <div id={'booking'}>
                <h1>Inspections Booked</h1>
                <p>{activeBasicBooks ? 'Basic' : activeAdvancedBooks ? 'Advanced' : 'All'}</p>
                <div className='d-flex justify-content-between align-items-start'>
                    <div className='search-button mr-2' onClick={() => this.showSearchBarHandler()}>
                        <div className={'search-icon'}>
                            <SearchIcon/>
                        </div>
                        Search
                    </div>
                    {!showSearchBar && 

                    <div className='d-flex'>
                        <Button
                        color="danger"
                        className="showFilterButton"
                        onClick={() => {
                            handleChangeSlider(null, [0,5]);
                        this.setState({showFilter: !this.state.showFilter });
                        }}
                    ><MaterialIcon icon="star" color="white"  />{this.state.showFilter ? 'Clear Filter' : 'Filter'}</Button>

                        <DateSelector setDateRange={setDateRange} dateRange={dateRange}/>

                    </div>
                    }
                </div>
                {showFilter ?
                <div className='d-flex justify-content-center'>
                        <div style={{width: '200px', marginTop: '20px', marginRight: '100px'}}>
                        <Typography id="range-slider" variant="h5" gutterBottom>
                            Filter By Rating:
                        </Typography>
                            <Slider
                                style={{color: '#E00000'}}
                                value={rangeSliderValues}
                                onChange={handleChangeSlider}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                                getAriaValueText={() => this.valuetext()}
                                min={0}
                                max={5}
                                step={.5}
                                valueLabelDisplay='auto'
                                />
                        </div>
                    </div>
                        : null }
                {showSearchBar && <div className='search-bar grid'>
                    <Input name={'name'}
                           value={search.name}
                           className='middle'
                           placeholder={'Customer or Vehicle Name'}
                           onChange={event => onInputChange(event)}
                    />
                    <IntlTelInput
                        preferredCountries={['ae']}
                        inputClassName={'form-control'}
                        inputName={'ownerPhoneNumber'}
                        inputId={'ownerInputPhoneNumber'}
                        separateDialCode={true}
                        onPhoneNumberChange={(status, value, countryData, number, id) => {
                            onInputChange({
                                target: {
                                    name: 'phone',
                                    value: value
                                }
                            });
                            onInputChange({
                                target: {
                                    name: 'phoneCode',
                                    value: value === "" ? "" : countryData.dialCode
                                }
                            });
                        }}
                        value={search.phone}
                    />
                    <DatePicker
                        selected={search.date}
                        onChange={handleChange}
                        className=""
                        placeholderText="Time"
                    />
                    <Input name={'reference'}
                           value={search.reference}
                           className='small'
                           placeholder={'Booking Reference'}
                           onChange={event => onInputChange(event)}
                    />
                    <Input name={'plate'}
                           value={search.plate}
                           className='small'
                           placeholder={'Plate'}
                           onChange={event => onInputChange(event)}
                    />
                    <Input name={'vin'}
                           value={search.vin}
                           className='small'
                           placeholder={'VIN'}
                           onChange={event => onInputChange(event)}
                    />
                    
                </div>}
                <Grid noSearchResults={noSearchResults} inspections={inspections} />
            </div>
        );
    }
}


function Hit(props) {
    console.log(props, 'props');
    return (
        <Inspection inspection={props.hit} />
    );
  }

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition), withRouter
)(Booking);
