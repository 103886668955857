import app from "firebase/app";
import {
  convertDateToFirestoreTimestamp,
  executeSearchQuery
} from "./firestoreUtils";
import { setEndDay, setStartDay } from "../../static/utils";

const getInspectionCollection = () => app.firestore().collection("inspections");

export const getInspections = (
  pageSize,
  lastElement,
  type,
  search,
  dateRange,
  rangeSliderValues = null
) => {
  const query = buildSearchQuery(
    pageSize,
    lastElement,
    type,
    search,
    dateRange,
    rangeSliderValues
  );
  console.log('before executeSearchQuery');
  return executeSearchQuery(query);
};

export const getInspectionId = () => {
  return new Promise(function(resolve, reject) {
    const docRef = getInspectionCollection().doc();
    resolve(docRef.id);
  });
};

export const createInspection = (dto, bookingId) => {
  return new Promise(function(resolve, reject) {
    dto.inspectionRef = bookingId.substr(0, 6).toUpperCase();
    const docRef = getInspectionCollection().doc(bookingId);
    docRef.set({ ...dto });
    if (docRef && docRef.id) {
      resolve(docRef.id);
    } else {
      reject("Object not created");
    }
  });
};

export const updateInspection = (dto, uid) => {
  return new Promise(function(resolve, reject) {
    const docRef = getInspectionCollection().doc(uid);
    docRef.update({ ...dto });
    if (docRef && docRef.id) {
      resolve(docRef.id);
    } else {
      reject("Object not created");
    }
  });
};

export const getInspectionsByTimeRange = (forTime, toTime) => {
  const query = buildTimeQuery(forTime, toTime);
  return executeSearchQuery(query);
};

export const getInspectionsByTime = time => {
  const query = buildTimeQuery(null, null, time);
  return executeSearchQuery(query);
};

const buildTimeQuery = (forTime, toTime, time) => {
  let query = getInspectionCollection();
  if (forTime) {
    query = query.where("bookingDate", ">=", forTime);
  }
  if (toTime) {
    query = query.where("bookingDate", "<=", toTime);
  }
  if (time) {
    query = query.where("bookingDate", "==", time);
  }
  return query;
};

const buildSearchQuery = (pageSize, lastElement, type, search, dateRange, rangeSliderValues) => {
  let query = getInspectionCollection();
  if (type) {
    query = query.where("inspectionType", "==", type);
  }
  if (search && search.name) {
    const cName = search.name;
    query = query.where("cName", "==", cName);
  }
  if (search && search.phoneCode) {
    query = query.where("cPhCode", "==", search.phoneCode);
  }
  if (search && search.phone) {
    query = query.where("cPhNumber", "==", search.phone);
  }
  if (search && search.plate) {
    query = query.where("vPlate", "==", search.plate);
  }
  if (search && search.vin) {
    query = query.where("vVIN", "==", search.vin);
  }
  if (search && search.reference) {
    query = query.where("inspectionRef", "==", search.reference);
  }
  if (search && search.date) {
    const forDate = setStartDay(search.date);
    query = query.where(
      "bookingDate",
      ">",
      convertDateToFirestoreTimestamp(forDate)
    );
  }

  if (search && search.date) {
    const toDate = setEndDay(search.date);
    query = query.where(
      "bookingDate",
      "<",
      convertDateToFirestoreTimestamp(toDate)
    );
  }

  if (dateRange && dateRange.fromDate) {
    query = query.where(
      "bookingDate",
      ">",
      convertDateToFirestoreTimestamp(dateRange.fromDate)
    );
  }

  if (dateRange && dateRange.toDate) {
    query = query.where(
      "bookingDate",
      "<",
      convertDateToFirestoreTimestamp(dateRange.toDate)
    );
  }

  // if (rangeSliderValues) {
  //   query = query.where('finalScore', '>=', rangeSliderValues[0]);
  //   query = query.where('finalScore', '<=', rangeSliderValues[1]);

  // }

  if (pageSize) {
    query = query.limit(pageSize);
  }
  if (lastElement) {
    query = query.startAfter(lastElement);
  }
  return query;
};