export const BASE_URL = 'localhost:3000';
export const GRID_SIZE = 8;
export const START_WORK_TIME = 0;
export const END_WORK_TIME = 23;
export const END_MINUTE_TIME = 55;
export const ALGO_DRIVEN_SERVER_URL ='https://algodriven.io';
export const ALGO_DRIVEN_AUTH_KEY = '72E88391C517F77F1FCDBE746D32A';
export const MAX_FILE_SIZE = 5242880;//kb
export const ALLOWED_IMAGE_EXTENTIONS = ['.jpg', '.png', '.gif'];
export const USER_ROLES = ['Booking Manager', 'Admin', 'Inspector'];
export const MAPS_KEY = 'AIzaSyAqERQDKoMoYS1S6RIlXIZYozNt1-OMGl0';
