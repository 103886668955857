import React, { useState, useCallback, useEffect } from "react";
import "./VehicleDetails.css";
import { Button, Form, FormGroup, Label } from "reactstrap";
import Input from "reactstrap/es/Input";
import { VehicleDetailsIcon } from "../../elements/Icons/Icons";
import { Uploader } from "../../elements/files/Uploader";
import { ifStringEmpty } from "../../../static/utils";
import Loader from "../../elements/Loader/Loader";
import { useDropzone } from "react-dropzone";
import { getMakeModels } from "../../../firebase/api/makemodels";
import MaterialIcon, { colorPalette } from "material-icons-react";
import ErrorComponent from "../../elements/Error/Error";
import {
  isStringValid,
  isEmailValid,
  isNumberInvalid
} from "../../../static/utils";

const VehicleDetails = ({
  vehicle,
  getVehicle,
  onInputChange,
  onDrop,
  getVehicleByMulkiya,
  getCarStockImg,
  handleDistanceTypeModal
}) => {
  let timeout = null;
  const [load, setLoad] = useState(true);
  const [loadingMessage, setLoadMessage] = useState("");
  const [makeModels, setMakeModels] = useState({});
  const [vehicleData, setVehicleData] = useState({});

  useEffect(() => {
    getMakeModels().then(result => {
      const data = {};
      result &&
        result.forEach(function(doc) {
          data[doc.ref.id] = Object.values(doc.data());
        });

      if (vehicle.make && vehicle.model)
        if (data[vehicle.make]) {
          data[vehicle.make].indexOf(vehicle.model) === -1 &&
            data[vehicle.make].push(vehicle.model);
        } else {
          data[vehicle.make] = [vehicle.model];
        }

      setMakeModels(data);
    });
  }, []);

  const onDropPicture = picture => {
    setLoad(false);
    setLoadMessage("Uploading Mulkiya");
    timeout = setTimeout(() => {
      setLoadMessage("Analyzing Mulkiya");
      timeout = setTimeout(() => {
        setLoadMessage("Loading Vehicle details");
      }, 40000);
    }, 40000);
    onDrop(picture);
    // getVehicleByMulkiya(picture,waitForDate);
    getVehicleByMulkiya(picture, waitForDate);
  };

  const waitForDate = (make, model) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    setLoad(true);
    const data = {};
    data[make] = { model };
    setVehicleData(data);
    getCarStockImg(vehicle.vin);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: files => onDropPicture(files)
  });

  const makes = Object.keys(makeModels);
  const models = makeModels ? makeModels[vehicle.make] : [];

  const vehicleMakes = Object.keys(vehicleData);
  const vehicleModels =
    vehicleData && vehicleData[vehicle.make]
      ? Object.keys(vehicleData[vehicle.make])
      : [];

  function getCurrentYear() {
    var currentYear = new Date().getFullYear();
    var max = currentYear + 1;
    var option = "";
    var years = [];
    for (var year = currentYear - 30; year <= max; year++) {
      var option = document.createElement("option");
      option.text = year;
      option.value = year;
      years[year] = year;
      //document.getElementById("year").appendChild(option)
    }
    return years;
  }

  function renderYearOptions() {
    const currentYear = new Date().getFullYear();
    const max = currentYear + 1;
    const years = [];
    for (let year = currentYear - 30; year <= max; year++) {
      years.push(
        <option key={year} label={year} value={year}>
          {year}
        </option>
      );
    }
    years.push(
      <option value="" key="">
        {" "}
      </option>
    );
    years.reverse();
    return years;
  }
  //   console.log(renderYearOptions(), "yesars");
  const years = renderYearOptions();
  return (
    <div id={"vehicleDetails"}>
      <div className={"createInspectionHeader"}>
        <VehicleDetailsIcon />
        <div>
          <h3>Vehicle Details</h3>
          <p>
            Please make sure these details are up to date as they will be used
            for your booking Inspections and communications.
          </p>
        </div>
      </div>
      <div className={"createInspectionBody"}>
        <Form className="d-flex flex-column" autoComplete="off">
          <div className="downloadForm d-flex justify-content-between">
            <FormGroup className="createFormGroup upload-vin">
              <Label for="carVIN">VIN</Label>
              <div className="d-flex align-items-center">
                <Input
                  type="text"
                  name="vin"
                  className="form-control"
                  id="carVIN"
                  placeholder="VIN"
                  disabled={!load}
                  onChange={event => onInputChange(event)}
                  value={vehicle.vin}
                />
                <Button
                  color="danger"
                  disabled={!load || ifStringEmpty(vehicle.vin)}
                  className="mx-2"
                  onClick={() => {
                    setLoad(false);
                    getVehicle(vehicle.vin, waitForDate);
                    getCarStockImg(vehicle.vin);
                  }}
                >
                  Load Vehicle
                </Button>
              </div>
            </FormGroup>
            <FormGroup className="createFormGroup">
              <Label for="carMalhiya">Upload Mulkiya</Label>
              <div className="d-flex align-items-center justify-content-end">
                <div className="d-flex w-100" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="drug-and-drop-input">
                    {vehicle.filename ? (
                      vehicle.filename
                    ) : (
                      <span className="d-flex align-items-center">
                        <MaterialIcon
                          icon="add_photo_alternate"
                          color="#858585"
                        />
                        Choose a file or drag it here.
                      </span>
                    )}
                  </div>
                </div>
                <Uploader buttonText="Upload photo" onDrop={onDropPicture} />
              </div>
            </FormGroup>
          </div>
          {load ? (
            <div className={"gridForm align-items-baseline"}>
              <FormGroup className="formShort">
                <Label for="year">Year </Label>
                {/* <Input
                  type="text"
                  list="year"
                  name="year"
                  value={vehicle.year}
                  onChange={event => onInputChange(event)}
                />
                <datalist id="year">
                  {years &&
                    years.map((year, index) => (
                      <option value={year} key={`${year}_${index}`}>
                        {year}
                      </option>
                    ))}
                </datalist> */}
                <Input
                  type="select"
                  name="year"
                  id="year"
                  value={vehicle.year}
                  onChange={event => {
                    onInputChange(event);
                  }}
                >
                  {renderYearOptions()}
                </Input>
              </FormGroup>

              <FormGroup className="formLong">
                <Label for="carMake">Make</Label>
                <div className="datalist-input">
                  <Input
                    type="text"
                    list="make"
                    name="make"
                    value={vehicle.make}
                    onChange={event => onInputChange(event)}
                  />
                </div>
                <datalist id="make">
                  <option />
                  {vehicleMakes &&
                    vehicleMakes.map((make, index) => (
                      <option value={make} key={`${make}_${index}`}>
                        {make}
                      </option>
                    ))}
                  {makes &&
                    makes.map((make, index) => (
                      <option value={make} key={`${make}_${index}`}>
                        {make}
                      </option>
                    ))}
                </datalist>
                <ErrorComponent
                  value={vehicle.make || ""}
                  type="make"
                  name="Make"
                />
                {/* <Input
                  type="select"
                  name="make"
                  id="carMake"
                  value={vehicle.make}
                  onChange={event => onInputChange(event)}
                >
                  <option />
                  {vehicleMakes &&
                    vehicleMakes.map((make, index) => (
                      <option value={make} key={`${make}_${index}`}>
                        {make}
                      </option>
                    ))}
                  {makes &&
                    makes.map((make, index) => (
                      <option value={make} key={`${make}_${index}`}>
                        {make}
                      </option>
                    ))}
                </Input> */}
              </FormGroup>
              <FormGroup className="formLong" autoComplete="off">
                <Label for="exampleSelect">Model</Label>
                <div className="datalist-input">
                  <Input
                    type="text"
                    list="model"
                    name="model"
                    value={vehicle.model}
                    onChange={event => onInputChange(event)}
                  />
                </div>
                <datalist id="model">
                  <option />
                  {vehicleModels &&
                    vehicleModels.map((model, index) => (
                      <option
                        value={vehicleData[vehicle.make][model]}
                        key={`${model}_${index}`}
                      >
                        {vehicleData[vehicle.make][model]}
                      </option>
                    ))}
                  {models &&
                    models.map((model, index) => (
                      <option value={model} key={`${model}_${index}`}>
                        {model}
                      </option>
                    ))}
                </datalist>
                <ErrorComponent
                  value={vehicle.model || ""}
                  type="model"
                  name="Model"
                />
                {/* <Input
                  type="select"
                  name="model"
                  id="carModel"
                  value={vehicle.model}
                  onChange={event => onInputChange(event)}
                >
                  <option />
                  {vehicleModels &&
                    vehicleModels.map((model, index) => (
                      <option
                        value={vehicleData[vehicle.make][model]}
                        key={`${model}_${index}`}
                      >
                        {vehicleData[vehicle.make][model]}
                      </option>
                    ))}
                  {models &&
                    models.map((model, index) => (
                      <option value={model} key={`${model}_${index}`}>
                        {model}
                      </option>
                    ))}
                </Input> */}
              </FormGroup>
              <FormGroup className="formLong">
                <Label for="carPlate">Plate No</Label>
                <Input
                  type="text"
                  name="plateNo"
                  id="carPlate"
                  placeholder=""
                  onChange={event => onInputChange(event)}
                  value={vehicle.plateNo}
                />
                <ErrorComponent
                  value={vehicle.plateNo || ""}
                  type="plateNumber"
                  name="Plate Number"
                />
              </FormGroup>
              {/* <FormGroup className='formShort'>
                            <Label for="carDistance" onClick={handleDistanceTypeModal}>{vehicle.distanceUnit}</Label>
                            <Input type="text"
                                   name="kms"
                                   id="carDistance"
                                   placeholder=""
                                   onChange={event => onInputChange(event)}
                                   onBlur={vehicle.kms && !isNumberInvalid(vehicle.kms) ? event => onBlur(event) : () => {}}
                                   value={vehicle.kms}
                            />
                        <ErrorComponent value={vehicle.kms} type="number" name="Distance" />
                        </FormGroup> */}
              <FormGroup className="formLong">
                <Label for="carEngineNumber">Engine Number</Label>
                <Input
                  type="text"
                  name="engineNumber"
                  id="carEngineNumber"
                  placeholder=""
                  onChange={event => onInputChange(event)}
                  value={vehicle.engineNumber}
                />
                <ErrorComponent
                  value={vehicle.engineNumber || ""}
                  type="engineNumber"
                  name="Engine Number"
                />
              </FormGroup>
              {/* <FormGroup className='formShort'>
                            <Label for="carGCC">GCC</Label>
                            <Input type="select" name="gcc" id="gcc"
                                   value={vehicle.gcc}
                                   onChange={event => {
                                       onInputChange(event)
                                   }}>
                                <option/>
                                <option label='Yes' selected={vehicle.gcc === 1}>1</option>
                                <option label='No' selected={vehicle.gcc === 0}>0</option>
                            </Input>
                        </FormGroup> */}
              <FormGroup className="formShort">
                <Label for="carColour">Colour</Label>
                <Input
                  type="text"
                  name="colour"
                  id="colour"
                  placeholder=""
                  onChange={event => onInputChange(event)}
                  value={vehicle.colour}
                />
                <ErrorComponent
                  value={vehicle.colour || ""}
                  type="color"
                  name="Color"
                />
              </FormGroup>
            </div>
          ) : (
            <div className={"gridForm"}>
              <div className="d-flex w-100 justify-content-center loader-wrapper">
                <div style={{ flex: 1, alignSelf: "center" }}>
                  <Loader />
                </div>
                {loadingMessage && (
                  <p style={{ alignSelf: "center" }}>{loadingMessage}</p>
                )}
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default VehicleDetails;
