import React, { Component } from "react";
import { compose } from "recompose";
import "./Grid.css";
import { withAuthorization } from "../../../session/index";
import Inspection from "../Inspection/Inspection";
import Loader from "../../elements/Loader/Loader";

class Grid extends Component {
  constructor(props) {
    super(props);
    console.log(props, "propspropsprops");
    this.state = {
      inspections:
        props && props.inspections ? props.inspections.slice(0, 30) : []
    };
  }
  componentDidMount() {
    this.recursive();
  }
  static getDerivedStateFromProps(props, state) {
    return {
      inspections:
        props && props.inspections ? props.inspections.slice(0, 30) : []
    };
  }
  recursive = () => {
    setTimeout(() => {
      let hasMore =
        this.state.inspections.length + 1 < this.props.inspections.length;
      this.setState(
        (prev, props) => ({
          inspections: props.inspections.slice(0, prev.inspections.length + 1)
        }),
        () => {
          if (hasMore) this.recursive();
        }
      );
    }, 10);
  };
  render() {
    console.log(this.props, "fuhyudyughdu");
    const { noSearchResults } = this.props;
    const { inspections } = this.state;
    return (
      <div className={"grid"}>
        {inspections && inspections.length > 0 ? (
          inspections &&
          inspections.length > 0 &&
          inspections.map((inspection, index) => {
            return <Inspection key={index} inspection={inspection} />;
          })
        ) : (
          <div className={"loaderWrapper"}>
            {noSearchResults ? "No search results..." : <Loader />}
          </div>
        )}
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition))(Grid);
