import React, {Component} from 'react';
import {Input} from "reactstrap";
import MaterialIcon from "material-icons-react";
import {USER_ROLES} from "../../../configs";
import {doPasswordReset} from "../../../firebase/api/auth";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import {deleteUser, deleteUserCloud} from "../../../firebase/api/users";

class UserRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editUser: false,
            showResetModal: false,
            showDeleteModal: false,
            showErrorModal: false,
            dto: {
                name: '',
                location: '',
                role: '',
            },
        }
    }

    componentDidMount() {
        this.setUser()
    }


    onCancelEdit = () => {
        this.setUser();
        const {editUser} = this.state;
        this.setState({editUser: !editUser});
    };

    setUser = () => {
        const {dto} = this.state;
        const {user} = this.props;
        dto.name = user.name ? user.name : '';
        dto.location = user.location ? user.location : '';
        dto.role = user.role ? user.role : '';
        this.setState({dto});
    };

    editUser = () => {
        const {index, editUser, user} = this.props;
        const {dto} = this.state;
        editUser(dto, user.uid, index);
        this.setState({editUser: false});
    };

    onInputChange = event => {
        const {dto} = this.state;
        dto[event.target.name] = event.target.value;
        this.setState({dto});
    };

    onEnterPressAdd = (event) => {
        const {dto} = this.state;
        if (event.key === 'Enter' && dto.name && dto.location && dto.role) {
            this.editUser()
        }
    };

    resetPasswordModalToggle = () => {
        this.setState(prevState => ({
            showResetModal: !prevState.showResetModal
        }));
    };

    deleteModalToggle = () => {
        this.setState(prevState => ({
            showDeleteModal: !prevState.showDeleteModal
        }));
    };

    resetPassword = () => {
        const {user} = this.props;
        user.email && doPasswordReset(user.email).then(res => {
            this.resetPasswordModalToggle();
        })
    };

    deleteUser = () => {
        const {user} = this.props;
        const {uid} = user;
        deleteUserCloud(uid).then((res) => {
            deleteUser(uid).then((response) => {
                this.props.deleteUser(user);
                this.deleteModalToggle();
            })
        }).catch(err => {
            this.handleErrorModal()
        })
    }

    handleErrorModal = () => {
        this.setState({ showErrorModal: true, showDeleteModal: false})
    }

    errorModalToggle = () => {
        this.setState(prevState => ({
            showErrorModal: !prevState.showErrorModal
        }));
    };

    render() {
        const {dto, editUser, showResetModal,showErrorModal, showDeleteModal} = this.state;
        const {locations, user} = this.props;
        const isButtonDisabled = dto.name && dto.location && dto.role;
        return (
            <tr>
                <td>{editUser
                    ? <Input name={'name'} placeholder={'Name'} value={dto.name}
                             onChange={event => this.onInputChange(event)}
                             onKeyPress={event => this.onEnterPressAdd(event)}
                    />
                    : user.name}
                </td>
                <td>{editUser
                    ? <Input name={'location'}
                             type="select" placeholder={'Location'}
                             value={dto.location}
                             onChange={event => this.onInputChange(event)}
                    >
                        <option/>
                        {locations && locations.map(location => (
                            <option>{location.name}</option>
                        ))}
                    </Input>
                    : user.location}</td>
                <td>{editUser
                    ? <Input name={'role'} type="select" placeholder={'Role'} value={dto.role}
                             onChange={event => this.onInputChange(event)}>
                        <option/>
                        {USER_ROLES && USER_ROLES.map(role => (
                            <option>{role}</option>
                        ))}
                    </Input>
                    : user.role}</td>
                <td>
                    {editUser
                        ? <div className='d-flex'>
                            <button className='option-button'
                                    onClick={() => this.editUser()} disabled={!isButtonDisabled}>
                                <i className="material-icons">how_to_reg</i>
                            </button>

                            <button className='option-button'
                                    onClick={() => {
                                        this.onCancelEdit()
                                    }}>
                                <i className="material-icons">reply</i>
                            </button>

                        </div>
                        : <div className='d-flex'>
                            <button className='option-button'
                                    onClick={() => this.setState({editUser: !editUser})}
                            >
                                <MaterialIcon icon="create"/>
                            </button>
                            {user.email && <button className='option-button'
                                                   onClick={() => this.resetPasswordModalToggle()}
                            >
                                <MaterialIcon icon="sms_failed"/>
                            </button>}
                            <button className='option-button'
                                    onClick={this.deleteModalToggle}
                            >
                                <MaterialIcon icon="delete"/>
                            </button>
                            <ConfirmationModal toggle={this.resetPasswordModalToggle}
                                               onAccept={this.resetPassword}
                                               modal={showResetModal}
                                               header={'Reset password'}
                                               body={`Are you sure you want to reset ${user.email} password?`}
                                               showButton={true}
                            />
                            <ConfirmationModal toggle={this.deleteModalToggle}
                                               onAccept={this.deleteUser}
                                               modal={showDeleteModal}
                                               header={'Delete User'}
                                               body={`Are you sure you want to delete ${user.name}?`}
                                               showButton={true}
                            />
                             <ConfirmationModal toggle={this.errorModalToggle}
                                               modal={showErrorModal}
                                               header={'Error'}
                                               body={"Intenal server error. Please try again"}
                                               showButton={false}
                            />
                        </div>}
                </td>
            </tr>
        )
    }
}

export default UserRow