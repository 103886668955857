import React from "react";
import app from "firebase/app";
import "./Inspection.css";
import CustomCarousel from "../../elements/Carousel/Carousel";
import * as STATUS from "../../../constants/status";
import {
  AdvancedIcon,
  BasicIcon,
  CarIcon,
  DateIcon,
  DocIcon,
  EmailIcon,
  PhoneIcon,
  SendIcon,
  SendIconBlack,
  TransportIcon,
  CheckedIcon,
  UnCheckedIcon,
  FlagIcon,
  EditIcon,
  BinIcon,
  RedoIcon
} from "../../elements/Icons/Icons";
import { compose } from "recompose";
import Input from "reactstrap/es/Input";
import { withAuthorization } from "../../../session";
import { FormGroup, Label, Button } from "reactstrap";
import ConfirmationModal from "../../elements/modal/ConfirmModal";
import { withRouter } from "react-router-dom";
import MaterialIcon from "material-icons-react";
import TextModal from "../../../components/elements/text-modal/TextModal";
import { sendEmail, getPdfUrl, redoInspection } from "../../../api/vehicle";
import { isEmailValid } from "../../../static/utils";
import CircularLoader from "../../elements/Loader/CircularLoader";

import "../../admin/ReportsTable/ReportsTable.css";
import {
  createBasicItem,
  deleteBasicItem,
  deleteReportBasicItem,
  editReportBasicItem,
  getAllReportBasic,
  getWheelTyresItems,
  getReportBasicItems,
  createWheelTyreItem,
  deleteWheelTyreItem
} from "../../../firebase/api/report";
import ReportRow from "../../admin/ReportsTable/ReportRow.js";
import Loader from "../../elements/Loader/Loader";
import FaultCodesBasicTable from "../../admin/FaultCodesTable/FaultCodesBasicTable.js";
import { isStringValid, isCategoryNameValid } from "../../../static/utils";

const getInspectionCollection = () => app.firestore().collection("inspections");
class Inspection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendEmailModal: false,
      redoInspectionModal: false,
      emailAddress: "",
      showSuccessModal: false,
      redoSuccessModal: false,
      redoInspectionRef: '',
      deleteModal: false,
      showErrorModal: false,
      showLoader: false,
      pdfUrl: "",

      itemsDisabled: false,
      addField: false,
      noItems: false,
      reports: {},
      items: {},
      activeItem: {},
      activeItemId: null,
      activeReportId: null,
      error: false,
      dto: {
        name: ""
      }
    };
  }
  inspectionEdit = inspection => {
    const uid = inspection.uid || inspection.objectID;
    const bookingDate =
      inspection && inspection.bookingDate ? inspection.bookingDate : {};
    inspection = {
      ...inspection,
      bookingDate: {
        ...bookingDate,
        seconds: bookingDate._seconds || bookingDate.seconds
      }
    };
    this.props.history.push({
      pathname: `edit/${uid}`,
      inspection: inspection
    });
  };

  deleteModalToggle = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal
    }));
  };

  sendEmailModalToggle = () => {
    const { inspection } = this.props;
    const { cEmail, vEmail } = inspection;
    const emailTextFieldValue =
      vEmail && cEmail && vEmail.toLowerCase() === cEmail.toLowerCase()
        ? cEmail
        : cEmail.concat(", ", vEmail);
    this.setState(prevState => ({
      sendEmailModal: !prevState.sendEmailModal,
      emailAddress: emailTextFieldValue
    }));
  };

  redoInspectionModalToggle = () => {
    const { inspection } = this.props;
    this.setState(prevState => ({
      redoInspectionModal: !prevState.redoInspectionModal
    }));
  };

  successModalToggle = () => {
    this.setState(prevState => ({
      showSuccessModal: !prevState.showSuccessModal
    }));
  };

  redoSuccessModalToggle = () => {
    this.setState(prevState => ({
      redoSuccessModal: !prevState.redoSuccessModal
    }));
  };

  errorModalToggle = () => {
    this.setState(prevState => ({
      showErrorModal: !prevState.showErrorModal
    }));
  };

  sendEmail = () => {
    const { emailAddress } = this.state;
    const { inspection } = this.props;

    const isValidEmail = this.isAllEmailsValid(emailAddress);
    if (isValidEmail) {
      const emailId = emailAddress.replace(/(^\s*,)|(,\s*$)/g, "");
      let id = "";
      if (typeof inspection.uid === "undefined") {
        id = inspection.objectID;
      } else {
        id = inspection.uid;
      }
      this.setState({
        showLoader: true
      });
      sendEmail({ inspectionId: id, emailId })
        .then(response => {
          if (response) {
            this.setState(
              {
                sendEmailModal: false,
                emailAddress: "",
                showLoader: false
              },
              () => {
                this.successModalToggle();
              }
            );
          } else {
            this.setState(
              {
                sendEmailModal: false,
                showLoader: false
              },
              () => {
                this.errorModalToggle();
              }
            );
          }
        })
        .catch(err => {
          this.setState(
            {
              sendEmailModal: false,
              showLoader: false
            },
            () => {
              this.errorModalToggle();
            }
          );
        });
    }
  };

  redoInspection = () => {
    const { inspection } = this.props;

    this.setState({
      showLoader: true
    });
    let inspectionId = inspection.uid ? inspection.uid : inspection.objectID;
    redoInspection(inspectionId)
    .then(response => {
      console.log('res', response);
      if (response) {
        this.setState(
          {
            redoInspectionModal: false,
            redoInspectionRef: response.inspectionRef,
            showLoader: false
          },
          () => {
            this.redoSuccessModalToggle();
          }
        );
      }
      console.log('response', response);
    });

    console.log('redoInspection', inspection);
  };

  inspectionDelete = inspection => {
    const SELF = this;
    const uid = inspection.uid || inspection.objectID;
    return new Promise(function(resolve, reject) {
      const docRef = getInspectionCollection().doc(uid);
      docRef
        .delete()
        .then(() => {
          SELF.deleteModalToggle();
          resolve("deleted successfully");
          window.location.reload();
        })
        .catch(() => {
          reject("Object not created");
        });
    });
  };

  onInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  renderModalBody = () => {
    const { inspection } = this.props;
    const { cEmail, vEmail } = inspection;
    const { sendEmailModal, emailAddress, showSuccessModal, redoInspectionModal } = this.state;
    const isValidEmail = this.isAllEmailsValid(emailAddress);
    return (
      <React.Fragment>
        <FormGroup className="createFormGroup d-flex flex-row createFormGroup ">
          <Label for="chooseLocation">
            <b>Email Address</b>
          </Label>
          <Input
            type="text"
            name="emailAddress"
            id="emailAddress"
            value={emailAddress}
            onChange={event => this.onInputChange(event)}
          />
        </FormGroup>
        <FormGroup className="createFormGroup d-flex flex-row createFormGroup ">
          <Label for="chooseLocation">
            <i>* Multiple addresses can input separate by commas.</i>
          </Label>
        </FormGroup>
        {vEmail && cEmail && vEmail.toLowerCase() !== cEmail.toLowerCase() && (
          <FormGroup className="createFormGroup d-flex flex-row createFormGroup ">
            <Label for="chooseLocation"></Label>
          </FormGroup>
        )}
        {emailAddress && !isValidEmail && (
          <span
            className="error"
            style={{
              marginLeft: "10px",
              fontSize: 13
            }}
          >
            Email address is Invalid
          </span>
        )}
      </React.Fragment>
    );
  };

  isAllEmailsValid = () => {
    const { emailAddress } = this.state;
    const emailIds = emailAddress.replace(/(^\s*,)|(,\s*$)/g, "");
    const allEmails = emailIds.split(",");
    for (let i = 0; i < allEmails.length; i++) {
      const isValidEmail = isEmailValid(allEmails[i].trim());
      if (!isValidEmail) {
        return false;
      }
    }
    return true;
  };

  onPdfclick = async inspection => {
    console.log("inspectioninspection", inspection);
    this.setState({ showLoader: true });
    getPdfUrl({ inspectionId: inspection.uid })
      .then(response => {
        if (response) {
          console.log("response in then", response);
          this.setState({ pdfUrl: response, showLoader: false });
          window.open(response);
        } else {
          this.setState(
            {
              showLoader: false
            },
            () => {
              this.errorModalToggle();
            }
          );
        }
      })
      .catch(async err => {
        console.log("errerr", err);
        getInspectionCollection()
          .doc(inspection.uid)
          .get()
          .then(response => {
            console.log("response in catch", response.data());
            const inspection = response.data();
            console.log("inspection data", inspection);
            console.log("inspection.pdfURL", inspection.pdfURL);
            if (inspection && inspection.pdfURL) {
              this.setState({ pdfUrl: inspection.pdfURL });
              window.open(inspection.pdfURL);
            }
          });
        this.setState({ showLoader: false });
      });
  };

  render() {
    const {
      reports,
      items,
      error,
      activeItem,
      dto,
      itemsDisabled,
      noItems,
      activeReportId,
      activeItemId
    } = this.state;

    const reportsKey = Object.keys(reports);
    const itemsKey = Object.keys(items);

    const { inspection } = this.props;
    const { pdfUrl } = this.state;
    const {
      sendEmailModal,
      redoInspectionModal,
      emailAddress,
      showSuccessModal,
      redoSuccessModal,
      deleteModal,
      showErrorModal,
      showLoader,
      redoInspectionRef
    } = this.state;
    if (!inspection || !inspection.bookingDate) {
      return null;
    }
    const isVehicleOwner =
      inspection.cName === inspection.vName &&
      inspection.cEmail === inspection.vEmail &&
      inspection.cPhCode === inspection.vPhCode &&
      inspection.cPhNumber === inspection.vPhNumber;
    const seconds =
      inspection.bookingDate._seconds || inspection.bookingDate.seconds;
    const theDate = inspection && new Date(seconds * 1000);
    const body = this.renderModalBody();
    return (
      <div className={"inspection"}>
        {showLoader && (
          <div>
            <CircularLoader />
          </div>
        )}
        <p className={"inspectionTitle"}>
          <CarIcon /> {inspection.vYear} {inspection.vMake} {inspection.vModel}
        </p>
        <div className={"inspectionWrapper"}>
          {inspection.status === STATUS.PENDING ? (
            <div
              className={"inspection-edit-button"}
              onClick={() => this.inspectionEdit(inspection)}
            >
              <EditIcon />
            </div>
          ) : (
            <div />
          )}

          {inspection.status === STATUS.PENDING ? (
            <div
              className={"inspection-delete-button"}
              onClick={() => this.deleteModalToggle()}
            >
              <BinIcon />
              <ConfirmationModal
                toggle={this.deleteModalToggle}
                onAccept={() => this.inspectionDelete(inspection)}
                modal={deleteModal}
                header={
                  "Are you sure you want to permanently delete this Inspection?"
                }
                body={
                  "This Inspection will be deleted immediately. You can’t undo this action."
                }
                showButton={true}
              />
            </div>
          ) : (
            <div />
          )}

          <div className="info-wrapper">
            <div className="customer-info">
              {!isVehicleOwner && <MaterialIcon icon="info" color="#454F63" />}
              <div className="customer-info-notification">
                <p className={"inspectionName"}>
                  <b>Vehicle Owner Details</b>
                </p>
                <p className={"inspectionName"}>{inspection.vName}</p>
                <p className={"inspectionPhone"}>
                  <PhoneIcon />
                  {`+${inspection.vPhCode}${inspection.vPhNumber}`}
                </p>
                <p className={"inspectionEmail"}>
                  <EmailIcon /> {inspection.vEmail}
                </p>
              </div>
            </div>
            <p className={"inspectionName"}>{inspection.cName}</p>
          </div>
          <p className={"inspectionPhone"}>
            <PhoneIcon />
            {`+${inspection.cPhCode}${inspection.cPhNumber}`}
          </p>
          <p className={"inspectionEmail"}>
            <EmailIcon /> {inspection.cEmail}
          </p>
          <div className="d-flex carInfo">
            <div className="carInfoRow1">
              <p>YEAR</p>
              <p>{inspection.vYear}</p>
            </div>
            <div className="carInfoRow1">
              <p>MAKE</p>
              <p>{inspection.vMake}</p>
            </div>
            <div className="carInfoRow1">
              <p>MODEL</p>
              <p>{inspection.vModel}</p>
            </div>
          </div>
          <div className="d-flex carInfo">
            <div className="d-flex flex-column align-items-center justify-content-between">
              <TransportIcon />
                <p className={'carPlate'}><p className={"carPlate"}>{inspection.vPlate == null || inspection.vPlate === "nil" || inspection.vPlate === "Nil" ? "-" : inspection.vPlate}</p></p>
            </div>
            <div>
              <p className="distanceUnit">{inspection.distanceUnit}</p>
              <p>{inspection.vKM != null ? inspection.vKM : "-"}</p>
            </div>
            <div>
              <p>COLOUR</p>
              <p className={"shortString"}>{inspection.vColour == null || inspection.vColour === "nil" || inspection.vColour === "Nil" ? "-" : inspection.vColour}</p>
            </div>
          </div>
          <div className="carImages">
            {inspection.carImages && inspection.carImages.length > 0 ? (
              <CustomCarousel carImages={inspection.carImages} />
            ) : (
              <img
                className={"defaultImage"}
                src={"images/default_car.jpg"}
                alt={"default-car"}
              />
            )}
          </div>
          <div className={`inspectionType ${inspection.inspectionType}`}>
            <p>
              {inspection.inspectionType === "Basic" ? (
                <BasicIcon />
              ) : (
                <AdvancedIcon />
              )}
              {inspection.inspectionType === "Basic"
                ? "Standard"
                : inspection.inspectionType}
            </p>
          </div>
          <div>
            <p>
              <CheckedIcon />
              {inspection.inspectionRef}
            </p>
          </div>

          <p className={"inspectionDate"}>
            <DateIcon />
            {` ${theDate.toLocaleDateString("en-GB", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric"
            })}`}
          </p>
            <p className={'inspectionVin'}><DocIcon />{inspection.vVIN == null || inspection.vVIN === "nil" || inspection.vVIN === "Nil" || inspection.vVIN == "0" ? "-" : inspection.vVIN}</p>
          <div className={"inspectionLine"} />
          {inspection.status === STATUS.PENDING ||
          inspection.status === STATUS.PROGRESS ? (
            <div className={"inspectionSendMail"}>
              <FlagIcon /> <b>Status:&nbsp;</b> {inspection.status}
            </div>
          ) : (
            <div className={"inspectionSendMail active"}>
            
              <FlagIcon />
              <div className="status-active">
            <div>
              {(inspection.pdfURL && inspection.pdfURL !== "") ||
              (pdfUrl && pdfUrl !== "") ? (
                <a
                  href={pdfUrl && pdfUrl !== "" ? pdfUrl : inspection.pdfURL}
                  target="_blank"
                >
                  <b>Status:&nbsp;</b> {inspection.status}
                </a>
              ) : (
                <a
                  className="pdf-link"
                  onClick={() => this.onPdfclick(inspection)}
                >
                  <b>Status:&nbsp;</b> {inspection.status}
                </a>
              )}
              </div>
               {inspection.status === STATUS.COMPLETE && inspection.finalScore != null && !!inspection.categoriesRatings ? (
                <div className="ratings-wrapper">
                  <div className="customer-info">
                    <p className={"inspectionNameMod"}>{inspection.finalScore}</p
                    ><MaterialIcon icon="star" color="#E00000" />
                    <div className="customer-info-notification">
                        <p className={"inspectionName"}>
                            <b>Category Ratings</b>
                        </p>
                        <p>
                            {Object.keys(inspection.categoriesRatings).map(
                              (value, index) => {
                                return (
                                  <ul className="category"> 
                                    <li>{`${value} - ${inspection.categoriesRatings[value]}\n`}</li>
                                  </ul>
                                );
                              }
                            )}
                        </p>
                    </div>
                  </div>
                </div>
                ) : (
                <div className="ratings-wrapper"></div>
            )}
         </div> 
            </div>
          )}
          {inspection.status === STATUS.PENDING ||
          inspection.status === STATUS.PROGRESS ? (
            <div className={"inspectionSendMail"}></div>
          ) : (
            <div className={"inspectionSendMail active"}>
              <SendIconBlack />
              <Button
                onClick={this.sendEmailModalToggle}
                style={{
                  color: "black",
                  paddingLeft: "0px",
                  backgroundColor: "transparent",
                  borderColor: "transparent"
                }}
              >
                <p>Resend</p>
              </Button>
                <RedoIcon />
              <Button
                onClick={this.redoInspectionModalToggle}
                style={{
                  color: "black",
                  paddingLeft: "0px",
                  backgroundColor: "transparent",
                  borderColor: "transparent"
                }}
              >
                <p>Redo Inspection</p>
              </Button>
            </div>
          )}
         
        </div>
        <ConfirmationModal
          toggle={this.sendEmailModalToggle}
          onAccept={this.sendEmail}
          modal={sendEmailModal}
          buttons={[null, <SendIcon />]}
          header={"Resend PDF Report"}
          body={body}
          showButton={true}
        />
        <ConfirmationModal
          toggle={this.redoInspectionModalToggle}
          onAccept={this.redoInspection}
          modal={redoInspectionModal}
          buttons={[null, <RedoIcon />]}
          header={"Redo Inspection"}
          body={<p>Are you sure you want to redo the Inspection?</p>}
          showButton={true}
        />
        <ConfirmationModal
          toggle={this.successModalToggle}
          modal={showSuccessModal}
          header={"Success"}
          body={`Report sent Successfully`}
          showButton={false}
        />
        <ConfirmationModal
          toggle={this.redoSuccessModalToggle}
          modal={redoSuccessModal}
          header={"Success"}
          body={`New Inspection Reference: ${redoInspectionRef}`}
          showButton={false}
        />
        <ConfirmationModal
          toggle={this.errorModalToggle}
          modal={showErrorModal}
          header={"Error"}
          body={`Your PDF Report couldn’t be sent. We’re sorry. Please try again later.`}
          showButton={false}
        />
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withAuthorization(condition), withRouter)(Inspection);
