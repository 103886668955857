import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {compose} from "recompose";
import './Landing.css'
import SignInForm from "../../components/elements/forms/SignInForm";
import {BlackKey} from "../../components/elements/Icons/Icons";

class Landing extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div className="landing-container">
                <div className={'landing-wrapper'}>
                    <img src={'images/logo_2.png'} alt={'logo'}/>
                    <BlackKey/>
                    <h3>Booking Web Dashboard</h3>                                        
                </div>
                <SignInForm/>
            </div>
        )
    }
}


export default compose(withRouter)(Landing);
