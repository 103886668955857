import React, {Component} from "react";
import './Main.css';
import {compose} from "recompose";
import {Route, Switch, withRouter} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {GRID_SIZE} from "../../configs";
import {withAuthorization} from "../../session/index";
import {Button, Container} from 'reactstrap';
import {doSignOut} from "../../firebase/api/auth";
import NavBar from "../../components/main/NavBar/NavBar";
import {getInspections} from "../../firebase/api/inspection";
import {BlackKey} from "../../components/elements/Icons/Icons";
import Booking from "../Booking";
import Create from "../Create";
import Footer from "../../components/main/Footer/Footer";
import ConfirmModal from "../../components/elements/modal/ConfirmModal";
import {setEndDay, setStartDay} from "../../static/utils";
import Admin from "../Admin";
import algoliasearch from 'algoliasearch/lite';
import moment from 'moment';
import Loader from '../../components/elements/Loader/Loader'


const searchClient = algoliasearch(
    '805BWM4PB3',
    'b24341550e1fe4bdfa89fdebbc1d6b25',
);

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeAllBooks: true,
            activeBasicBooks: false,
            activeAdvancedBooks: false,
            inspections: [],
            lastVisibleDocument: null,
            showLeaveModal: false,
            noSearchResults: false,
            showLoader: false,
            search: {
                name: '',
                phoneCode: '',
                phone: '',
                plate: '',
                vin: '',
                date: null,
                reference: ''
            },
            dateRange: {
                fromDate: setStartDay(new Date()),
                toDate: setEndDay(new Date())
            },
            allInspections: [],
            rangeSliderValues: [0, 5]
        }
    }

    componentDidMount() {
        const {dateRange, lastVisibleDocument} = this.state;
        this.getInspections(lastVisibleDocument, null, true, null, dateRange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            const {pathname} = this.props.location;
            pathname !== ROUTES.BOOKING && this.setState({
                activeAllBooks: false,
                activeBasicBooks: false,
                activeAdvancedBooks: false
            });
            pathname === ROUTES.BOOKING && this.setState({activeAllBooks: true})
        }
    }

    getAllInspections = async () => {

        this.getInspections(null, null, true, null, null)
    }

    handleChange = async (data) => {
        const {search, inspections} = this.state;
        this.setState({showLoader: true});

        search.date = data;
        // await this.getInspections(null, null, true, null, null).then(res => {
        this.setState({search}, async () => {
            const {inspections, allInspections} = this.state;
            this.filterInspections(allInspections)
        })
        // })


    };

    searchInspection = () => {
        const {search} = this.state;
        this.setState({lastVisibleDocument: null},
            () => this.refreshGrid(true, search, null))

    };

    setDateRange = (fromDate, toDate) => {
        const {dateRange} = this.state;
        dateRange.fromDate = setStartDay(fromDate);
        dateRange.toDate = setEndDay(toDate);
        this.setState({dateRange}, () => this.refreshGrid(true, null, dateRange));
    };

    handleChangeSlider = (event, newValue) => {
        this.setState({
            rangeSliderValues: newValue
        }, () => this.refreshGrid(true, null, this.state.dateRange, newValue))
    };

    setActiveTab = ({activeAllBooks, activeBasicBooks, activeAdvancedBooks}, getBooks) => {
        const {pathname} = this.props.location;
        pathname === ROUTES.BOOKING
            ? this.setState({
                activeAllBooks: activeAllBooks || false,
                activeBasicBooks: activeBasicBooks || false,
                activeAdvancedBooks: activeAdvancedBooks || false,
                inspections: [],
                lastVisibleDocument: null
            }, () => {
                getBooks()
            })
            : this.showLeaveModalToggle();
    };

    showLeaveModalToggle = () => {
        this.setState(prevState => ({
            showLeaveModal: !prevState.showLeaveModal
        }));
    };

    onLeaveModalAccept = () => {
        this.props.history.push(ROUTES.BOOKING);
        this.showLeaveModalToggle();
    };


    getQueries = () => {
        const {search} = this.state;
        const {name, vin, reference, plate, phone} = search;
        let queries = [];
        const data = {
            vName: name,
            cName: name,
            inspectionRef: reference,
            vPlate: plate,
            vVIN: vin,
            cPhNumber: phone,
            vVIN_suffixes: vin
        }
        queries = Object.keys(data)
            .filter(key => data[key] !== '' && data[key] !== undefined)
            .map(key =>
                ({
                    indexName: 'inspections',
                    query: data[key],
                    params: {restrictSearchableAttributes: [key]},
                })
            )
        if (queries.length === 0) {
            return [{
                indexName: 'inspections',
                query: '',
            }]
        }
        return queries;
    }

    getQueryHits = (results) => {
        const query = {};
        for (const result of results) {
            if (!!query[result.query]) {
                if (result.hits.length > query[result.query].length) {
                    return result.hits
                }
                return query[result.query];
            } else {
                query[result.query] = result.hits;
            }
        }
        return [];
    }


    concatInspections = (results) => {
        const hits = this.getQueryHits(results);
        if (results && results.length > 0) {
            const obj = {};
            const filteredInspections = [];
            results.forEach(result => {
                const arr = hits.length > 0 ? hits : result.hits;
                arr.forEach((hit) => {
                    obj[hit.inspectionRef] = !!obj[hit.inspectionRef] ? Number(obj[hit.inspectionRef]) + 1 : 1;
                    if (obj[hit.inspectionRef] === results.length) {
                        filteredInspections.push(hit)
                    }
                });
            })
            return filteredInspections
        }
        return [];
    }

    onInputChange = event => {
        const {search} = this.state;
        search[event.target.name] = event.target.value;
        this.setState({search}, () => {

            const values = Object.values(search);
            const hasValues = values.find(value => value !== '' && value !== undefined);
            if (hasValues) {
                const queries = this.getQueries();
                searchClient.search(queries, (err, obj = {}) => {
                    const inspections = this.concatInspections(obj.results)
                    this.filterInspections(inspections);
                })
            } else {
                this.updateGrid(() => {}, false);
            }
        });
    };

    refreshGrid = (clearGrid, search, dateRange, rangeSliderValues = null) => {
        if (rangeSliderValues == null) {
            rangeSliderValues = this.state.rangeSliderValues;
        }
        console.log('refreshGrid');
        const {activeAdvancedBooks, activeBasicBooks, lastVisibleDocument} = this.state;
        this.setState({noSearchResults: false});
        return activeAdvancedBooks ? this.getInspections(lastVisibleDocument, 'Advanced', clearGrid, search, dateRange, rangeSliderValues)
            : activeBasicBooks ? this.getInspections(lastVisibleDocument, 'Basic', clearGrid, search, dateRange, rangeSliderValues)
                : this.getInspections(lastVisibleDocument, null, clearGrid, search, dateRange, rangeSliderValues);
    };

    getInspections = (lastElement, type, clearGrid, search, dateRange, rangeSliderValues = null) => {
        return new Promise(async (resolve) => {
            getInspections(null, lastElement, type, search, dateRange, rangeSliderValues).then(results => {
                resolve(results)
                this.populateSearchResult(results, clearGrid);
            });
        })
    }

    updateGrid = (callback, showAll) => {
        const {dateRange} = this.state;
        const range = showAll ? null : dateRange;
        this.refreshGrid(true, null, range);
        callback();
    };


    populateSearchResult = (results, clearGrid) => {
        const inspections = clearGrid ? [] : [...this.state.inspections];
        let noSearchResults = true;
        if (results) {
            let lastVisibleDocument = null;
            if (this.state.rangeSliderValues[0] !== 0 || this.state.rangeSliderValues[1] !== 5) {
                results.forEach(function (doc) {
                    // lastVisibleDocument = doc;
                    const data = doc.data();
                    console.log('here', data.finalScore);
                    if (data.finalScore >= this.state.rangeSliderValues[0] && data.finalScore <= this.state.rangeSliderValues[1]) {
                        data.uid = doc.ref.id;
                        inspections.push(data);
                    }

                }.bind(this))
                console.log('inspections.length', inspections.length);
                if (inspections.length == 0) {
                    noSearchResults = true;
                } else {
                    noSearchResults = false;
                }


            } else {

                
                results.forEach(function (doc) {
                    // lastVisibleDocument = doc;
                    const data = doc.data();
                    data.uid = doc.ref.id;
                    inspections.push(data);
                });
            }
            
            this.setState({inspections, lastVisibleDocument, allInspections: inspections, noSearchResults});

        } else {
            this.setState({
                inspections: [],
                lastVisibleDocument: null,
                noSearchResults: noSearchResults
            });
        }
    };

    signOut = () => {
        doSignOut().then(() => this.props.history.push(ROUTES.LANDING));
    };

    filterInspections = (filteredData) => {
        const {search} = this.state;
        const data = [];
        const {phone, date, phoneCode} = search;
        if (filteredData && filteredData.length > 0) {
            filteredData.forEach((obj) => {
                let isContainDate = false
                let isContainPhone = false;
                let bookingDateSec = obj.bookingDate._seconds || obj.bookingDate.seconds
                if (date && obj && obj.bookingDate && bookingDateSec) {
                    const bookingDate = moment(new Date(bookingDateSec * 1000));
                    const filterDate = moment(date);
                    isContainDate = filterDate.isSame(bookingDate, 'date')
                }
                if (phone && obj.cPhCode && String(obj.cPhCode).includes(phoneCode)) {
                    isContainPhone = true
                }


                if ((!date || isContainDate) && (!phoneCode || isContainPhone)) {
                    data.push(obj)
                }

            })
        }
        let state = {
            noSearchResults: filteredData.length ? false : true,
            inspections: []
        }
        if (data.length !== 0) {
            state['inspections'] = data
        }
        this.setState({showLoader: false});
        this.setState(state)
    }

    render() {
        const {activeAllBooks, activeBasicBooks, activeAdvancedBooks, showLoader, inspections, showLeaveModal, noSearchResults, search, dateRange, rangeSliderValues} = this.state;

        return (
            <Container className='p-0' fluid>
                <div className='bodyWrapper'>

                    <header className={'header'}>
                        <div className={'headerLogo'}
                            onClick={() => this.setActiveTab({activeAllBooks: true}, () => this.refreshGrid(true, null, dateRange))}
                        >
                            <img src={'images/logo_2.png'} alt={'logo'} />
                        </div>
                        <div>
                            <h3>Booking System <BlackKey /></h3>
                        </div>
                    </header>
                    {showLoader &&
                        <div>
                            <Loader />
                        </div>
                    }
                    <div className='d-flex justify-content-between'>
                        <NavBar activeAllBooks={activeAllBooks}
                            activeBasicBooks={activeBasicBooks}
                            activeAdvancedBooks={activeAdvancedBooks}
                            setActiveTab={this.setActiveTab}
                            refreshGrid={this.refreshGrid}
                            dateRange={dateRange}
                            signOut={this.signOut} />

                        <Switch>
                            <Route exact path={ROUTES.BOOKING} render={() =>
                                <Booking
                                    getAllInspections={this.getAllInspections}
                                    activeAllBooks={activeAllBooks}
                                    activeBasicBooks={activeBasicBooks}
                                    activeAdvancedBooks={activeAdvancedBooks}
                                    inspections={inspections}
                                    search={search}
                                    handleChange={this.handleChange}
                                    updateGrid={this.updateGrid}
                                    onInputChange={this.onInputChange}
                                    searchInspection={this.searchInspection}
                                    setDateRange={this.setDateRange}
                                    handleChangeSlider={this.handleChangeSlider}
                                    rangeSliderValues={rangeSliderValues}
                                    noSearchResults={noSearchResults}
                                    dateRange={dateRange}
                                    filterInspections={this.filterInspections}
                                />
                            } />
                            <Route exact path={ROUTES.CREATE} render={() =>
                                <Create updateGrid={this.updateGrid} />
                            } />
                            <Route exact path={ROUTES.EDIT} render={() =>
                                <Create updateGrid={this.updateGrid} />
                            } />
                            <Route exact path={ROUTES.ADMIN} render={() =>
                                <Admin />
                            } />
                        </Switch>
                    </div>
                </div>
                <Footer />
                <ConfirmModal
                    header={'Warning!'}
                    body={'Do you want leave this page?'}
                    modal={showLeaveModal}
                    toggle={this.showLeaveModalToggle}
                    onAccept={this.onLeaveModalAccept}
                    showButton={true}
                />
            </Container>
        );
    }
}

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition), withRouter
)(Main);
